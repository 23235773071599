html body{
  font-family: 'Titillium Web', sans-serif;
  color: "#05335c";

  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

}



html { font-size: calc(.5em + .5vw); }



#basic-nav-dropdown {
  color: black;
}



.mediaIcon {
transition: all .5s ease-in-out;
}

.mediaIcon:hover {
transform: scale(1.7);
}


.logoAndLinkIcon {
transition: all .5s ease-in-out;
}

.logoAndLinkIcon:hover {
transform: scale(1.3);
}

.contactButton {
transition: all .5s ease-in-out;
}

.contactButton:hover {
transform: scale(1.3);

}

.subLink {
transition: all .5s ease-in-out;

}

.subLink:hover {
transform: scale(1.01);

}


.servicesImage {
transition: all .5s ease-in-out;
}

.servicesImage:hover {
transform: scale(1.2);
}

.missionInfo {
transition: all .5s ease-in-out;
}

.missionInfo:hover {
transform: scale(1.05);
}


#uncontrolled-tab-example {
  font-size: 2rem;
}

.navbarTitle  {
  font-family: 'Mr Dafoe', cursive;
}

.phoneNumber {
  font-family: 'Mr Dafoe', cursive;
}

.nav-link.active {
  color: white !important;
  background-color:#4a818f !important;
}

.nav-link {
  color: black !important;
  font-size: 1.5rem;
  border-width: none;

}


@media only screen and (max-width: 600px) {

  .LandingPageVideo {
    height: max-content !important;
    padding-bottom: 0 !important;
  }

  .contactRow {
    margin: 0 !important;
  }


  .cardFront {

    margin-left: 0 !important;
    position: relative !important;
    margin-top: 0 !important;
    height: fit-content !important;
    padding-top: 0 !important;


  }

  .formModal {
    margin: 0 !important;
  }

  .footerAbout {
    margin-top: 65rem !important;
  }

  .aboutCard {

    margin: 0 !important;
    color: black !important;
    border-bottom: 1px solid black !important;
  }

  .aboutCardRow {
    margin-top: 24rem !important;
  }


  .contactRow {
    padding: 0 !important;
  }

  .contactForm1 {


    height: 45rem !important;
  }



  .servicesPage {
    height: 45vw !important;
  }

  .servicesCardHeader {
    padding: 0 !important;
    font-size: 1rem !important;
    height: auto !important;
  }

  .servicesCard {
    font-size: .7rem !important;
    margin-top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 2rem !important;




  }

}